const WINDOW_WIDTH_BREAKPOINT = 700;

//// MOBILE FULL VIEWPORT FIX
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
function updateWindowHeight() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
updateWindowHeight(); // Must be done before the carousel init.
window.addEventListener("resize", updateWindowHeight);
window.addEventListener("orientationchange", updateWindowHeight);

////// CAROUSEL
const wrapper = document.querySelector(".c-carousel__wrapper"),
      carouselElem = document.querySelector(".c-carousel"),
      CAROUSEL_SLIDE_COUNT = wrapper.children.length;

//// SLIDE SHUFFLE
// From https://stackoverflow.com/questions/7070054/javascript-shuffle-html-list-element-order
for(let i = CAROUSEL_SLIDE_COUNT; i >= 0; i--) {
  wrapper.appendChild(wrapper.children[Math.random() * i | 0]);
}

//// GLIDER SETUP
const g = new Glider(wrapper);

g.onChange = function() {
  updateLogoAndCaption(g);
}

function gliderInit() {
  carouselElem.classList.remove("c-carousel--swiper");
  carouselElem.classList.add("c-carousel--glider");
  g.attach();
}

//// SWIPER SETUP
var s;

const swiperConfig = {
  init: false,
  loop: true,
  slidesPerView: 'auto',
  loopedSlides: 2,
  spaceBetween: 0,
  centeredSlides: true,
  speed: 500,
  navigation: {
    nextEl: '.c-carousel__button--next',
    prevEl: '.c-carousel__button--prev',
  },
  initialSlide: 0
};

function swiperInit() {
  s = new Swiper(carouselElem, swiperConfig);
  
  s.on('init', function() {
    updateLogoAndCaption(s)
    carouselElem.classList.add("swiper-container-initialized");
  });

  s.on('destroy', function() {
    carouselElem.classList.remove("swiper-container-initialized");
    wrapper.style.setProperty("transform", "none");
  });

  s.on('slideChange', function() {
    updateLogoAndCaption(s)
  });

  carouselElem.classList.remove("c-carousel--glider");
  carouselElem.classList.add("c-carousel--swiper");

  s.init();
  s.update();
}



////
//// CAPTION AND LOGO COLOR LOGIC
////

const caption = document.querySelector(".c-carousel__caption"),
      logo = document.querySelector(".c-logo"),
      logoLink = document.querySelector(".c-logo a");

function updateLogoAndCaption(s) {
  const index = s.activeIndex;

  // Change caption content
  cloneChildren(s.slides[index].querySelector("figcaption"), caption);

  // Change caption color
  caption.style.color = calculateTextColor(s.slides[index]);

  // Adapt caption width
  const windowW = getWindowWidth();
  if(windowW > WINDOW_WIDTH_BREAKPOINT) {
    caption.style.paddingLeft = caption.style.paddingRight =
      Math.min(
        Math.max(
          (windowW - s.slides[index].clientWidth) / 2 + 18,
          78,
          (windowW - 600) / 2,
        ),
        (windowW - 300) / 2
      ).toString() + "px";

    } else {
      // Caption is positioned at the bottom.
      caption.style.paddingLeft = caption.style.paddingRight = "6px";
    }

  // Change logo color
  let color;
  // Check if the current cell is wider than the viewport.
  if(s.slides[index].clientWidth < windowW - 70) {
    // If it's narrower, give the logo the contrast color for the previous slide.
    let prevIndex = (index === 0) ? s.slides.length - 1 : index - 1;
    color = calculateTextColor(s.slides[prevIndex]);
  } else {
    // If it's wider, give the logo the same color as the caption.
    color = caption.style.color;
  }

  switch(color) {
    case "white":
      if(!logo.classList.contains("c-logo--white")) {
        logoLink.classList.add("u-notransition");
        logo.classList.add("c-logo--white");
      }
      break;
    default:
      if(logo.classList.contains("c-logo--white")) {
        logoLink.classList.add("u-notransition");
        logo.classList.remove("c-logo--white");
      }
  }
  logoLink.offsetHeight; // Trigger reflow
  logoLink.classList.remove("u-notransition");
}



////
//// SWITCHING LOGIC
////

var prevShouldUseGlider = shouldUseGlider();
window.addEventListener("resize", function() {
  const curShoudUseGlider = shouldUseGlider();
  
  // Switch system on resize if needed
  if(curShoudUseGlider !== prevShouldUseGlider) {
    // Glider
    if(curShoudUseGlider) {
      console.log("Switching to Glider!");

      s.destroy(true, false);

      g.attach();
      updateLogoAndCaption(g);
    }
    // Swiper
    else {
      console.log("Switching to Swiper!");
      g.detach();
      wrapper.scrollLeft = 0;

      swiperInit();
      updateLogoAndCaption(s);
    }
  }

  // Update caption on resize
  if(curShoudUseGlider) {
    g.update();
    updateLogoAndCaption(g);
  } else {
    updateLogoAndCaption(s);
  }

  prevShouldUseGlider = curShoudUseGlider;
}, {passive: true});



////
//// INITIALIZATIION
////

if(prevShouldUseGlider) {
  gliderInit();
} else {
  swiperInit();
}



////
//// HELPERS
////

function shouldUseGlider() {
  return parseInt(getComputedStyle(document.documentElement)
    .getPropertyValue('--c-carousel-touch')) === 1;
    // Add to exclude Safari
    // || (navigator.userAgent.indexOf("Safari") > -1
    //     && navigator.userAgent.indexOf("Chrome") === -1);
}

function getWindowWidth() {
  return window.innerWidth || document.documentElement.clientWidth;
}

function calculateTextColor(node) {
  switch(node.dataset.captionColor) {
    case '#fff': return 'white';
    case 'white': return 'white';
    case '#000': return 'black';
    case 'black': return 'black';
    default: return parseInt(node.dataset.brightness) > 60 ? "black" : "white";
  }
}

function removeChildren(node) {
  var el;
  while(el = node.firstChild) {
    el.remove();
  }
}

function cloneChildren(srcNode, destNode) {
  removeChildren(destNode);
  var el;
  for(var i = 0; i < srcNode.childNodes.length; i++) {
    var clone = srcNode.childNodes[i].cloneNode(true);
    destNode.appendChild(clone);
  }
}
