//// "GLIDER" LOGIC

class Glider {
  constructor(elem) {
    this.wrapper = elem;
    this.slides = this.wrapper.children;

    this.widths = []; // Array of slide widths in px
    this.center = 0; // Wrapper half-length in px

    this.activeIndex = -1;

    this.onChange = null;

    const self = this;
    this.scrollListener = function() {
      self.updateCurrentIndex();
    };
  }

  attach() {
    const self = this;
    this.wrapper.addEventListener("scroll", this.scrollListener, {passive: true});
    this.update();

    // Randomize position
    this.wrapper.scrollLeft = Math.random() * this.widths[this.widths.length - 1];
  }

  detach() {
    this.wrapper.removeEventListener("scroll", this.scrollListener);
  }

  updateCurrentIndex() {
    let activeIndex = this.positionToIndex(this.wrapper.scrollLeft + this.center);
    if(activeIndex !== this.activeIndex) {
      this.activeIndex = activeIndex;
      try {
        if(typeof this.onChange === "function")
          this.onChange.call(this);
      } catch(e) {
        // Silently fail.
      }
    }
  }

  update() {
    this.updateWidths();
    this.updateCenter();
    this.updateCurrentIndex();
  }

  updateWidths() {
    var prev = 0;
    for(let i = 0; i < this.slides.length; i++) {
      let w = this.slides[i].getBoundingClientRect().width;
      this.widths[i] = prev + w;
      prev = this.widths[i];
    }
  }

  updateCenter() {
    this.center = this.wrapper.getBoundingClientRect().width / 2;
  }

  positionToIndex(p) {
    for(let i = 0; i < this.widths.length; i++) {
      if(p < this.widths[i]) return i;
    }
  }
}
