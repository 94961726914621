//// OVERLAY LOGIC

const overlay = document.querySelector(".c-overlay"),
      trigger = document.querySelector(".js-overlay-trigger");

trigger.addEventListener("click", function(e) {
  e.preventDefault();

  // Alternative, direct way (cf. CSS).
  //overlay.classList.toggle("c-overlay--visible");

  document.body.classList.toggle("c-overlay--visible__body");

  // Scroll after the CSS overlay behavior change.
  if(document.body.classList.contains("c-overlay--visible__body")) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
});
